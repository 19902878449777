import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from './components/ui/alert';

// APIキーを取得する関数（実際の実装はプロジェクトの設定に応じて変更してください）
const getApiKey = () => {
  // 例: 環境変数から取得
  return process.env.REACT_APP_TRANSLATOR_API_KEY;
  // または、別の安全な方法でAPIキーを取得する処理を実装
};

const TextToSpeechApp = () => {
  const [text, setText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [rate, setRate] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [voices, setVoices] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [showWarning, setShowWarning] = useState(false);
  const [isTranslating, setIsTranslating] = useState(false);
  const utteranceRef = useRef(null);

const supportedLanguages = [
  { code: 'ja-JP', name: '日本語' },
  { code: 'en-US', name: '英語（アメリカ）' },
  { code: 'en-UK', name: '英語（イギリス）' },
  { code: 'zh-CN', name: '中国語（簡体字）' },
  { code: 'zh-HK', name: '中国語（香港）' },
  { code: 'zh-TW', name: '中国語（繁体字）' },
  { code: 'ar-SA', name: 'アラビア語' },
  { code: 'cs-CZ', name: 'チェコ語' },
  { code: 'da-DK', name: 'デンマーク語' },
  { code: 'de-DE', name: 'ドイツ語' },
  { code: 'el-GR', name: 'ギリシャ語' },
  { code: 'es-ES', name: 'スペイン語' },
  { code: 'fi-FI', name: 'フィンランド語' },
  { code: 'fr-FR', name: 'フランス語' },
  { code: 'he-IL', name: 'ヘブライ語' },
  { code: 'hi-IN', name: 'ヒンディー語' },
  { code: 'hu-HU', name: 'ハンガリー語' },
  { code: 'id-ID', name: 'インドネシア語' },
  { code: 'it-IT', name: 'イタリア語' },
  { code: 'ko-KR', name: '韓国語' },
  { code: 'nl-NL', name: 'オランダ語' },
  { code: 'no-NO', name: 'ノルウェー語' },
  { code: 'pl-PL', name: 'ポーランド語' },
  { code: 'pt-BR', name: 'ポルトガル語（ブラジル）' },
  { code: 'pt-PT', name: 'ポルトガル語（ポルトガル）' },
  { code: 'ro-RO', name: 'ルーマニア語' },
  { code: 'ru-RU', name: 'ロシア語' },
  { code: 'sk-SK', name: 'スロバキア語' },
  { code: 'sv-SE', name: 'スウェーデン語' },
  { code: 'th-TH', name: 'タイ語' },
  { code: 'tr-TR', name: 'トルコ語' },
  { code: 'my-MM', name: 'ミャンマー語（ビルマ語）' },
  { code: 'lo-LA', name: 'ラオス語' },
  { code: 'af-ZA', name: 'アフリカーンス語' },
  { code: 'am-ET', name: 'アムハラ語' },
  { code: 'az-AZ', name: 'アゼルバイジャン語' },
  { code: 'bn-IN', name: 'ベンガル語' },
  { code: 'bg-BG', name: 'ブルガリア語' },
  { code: 'km-KH', name: 'クメール語' },
  { code: 'ca-ES', name: 'カタルーニャ語' },
  { code: 'hr-HR', name: 'クロアチア語' },
  { code: 'et-EE', name: 'エストニア語' },
  { code: 'fa-IR', name: 'ペルシャ語' },
  { code: 'fil-PH', name: 'フィリピン語' },
  { code: 'ka-GE', name: 'ジョージア語' },
  { code: 'gu-IN', name: 'グジャラート語' },
  { code: 'is-IS', name: 'アイスランド語' },
  { code: 'ga-IE', name: 'アイルランド語' },
  { code: 'kn-IN', name: 'カンナダ語' },
  { code: 'kk-KZ', name: 'カザフ語' },
  { code: 'lt-LT', name: 'リトアニア語' },
  { code: 'lv-LV', name: 'ラトビア語' },
  { code: 'mk-MK', name: 'マケドニア語' },
  { code: 'ms-MY', name: 'マレー語' },
  { code: 'ml-IN', name: 'マラヤーラム語' },
  { code: 'mr-IN', name: 'マラーティー語' },
  { code: 'mn-MN', name: 'モンゴル語' },
  { code: 'ne-NP', name: 'ネパール語' },
  { code: 'pa-IN', name: 'パンジャーブ語' },
  { code: 'si-LK', name: 'シンハラ語' },
  { code: 'sl-SI', name: 'スロベニア語' },
  { code: 'sw-KE', name: 'スワヒリ語' },
  { code: 'ta-IN', name: 'タミル語' },
  { code: 'te-IN', name: 'テルグ語' },
  { code: 'ur-PK', name: 'ウルドゥー語' },
  { code: 'vi-VN', name: 'ベトナム語' },
  { code: 'cy-GB', name: 'ウェールズ語' },
  { code: 'yo-NG', name: 'ヨルバ語' },
  { code: 'zu-ZA', name: 'ズールー語' },
];

  const [language, setLanguage] = useState('en-US');

  useEffect(() => {
    const updateVoices = () => {
      const availableVoices = window.speechSynthesis.getVoices();
      setVoices(availableVoices);
      
      const voiceForLanguage = availableVoices.find(voice => voice.lang === language);
      setSelectedVoice(voiceForLanguage || null);
      setShowWarning(!voiceForLanguage);
    };
    
    window.speechSynthesis.onvoiceschanged = updateVoices;
    updateVoices();
  }, [language]);

  useEffect(() => {
    if (text) {
      translateText();
    }
  }, [language, text]);

  const translateText = async () => {
    if (!text) return;

    setIsTranslating(true);
    const apiKey = getApiKey();
    const endpoint = 'https://api.cognitive.microsofttranslator.com';
    const location = 'southeastasia';

    try {
      const response = await axios.post(`${endpoint}/translate`, [{
        'text': text
      }], {
        headers: {
          'Ocp-Apim-Subscription-Key': apiKey,
          'Ocp-Apim-Subscription-Region': location,
          'Content-type': 'application/json',
        },
        params: {
          'api-version': '3.0',
          'to': language.split('-')[0]
        }
      });

      setTranslatedText(response.data[0].translations[0].text);
    } catch (error) {
      console.error('Translation error:', error);
      setTranslatedText('Translation error occurred');
    } finally {
      setIsTranslating(false);
    }
  };

  const speak = () => {
    if (isTranslating) {
      alert('翻訳中です。しばらくお待ちください。');
      return;
    }
    
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(translatedText);
      utterance.lang = language;
      utterance.rate = rate;

      if (selectedVoice) {
        utterance.voice = selectedVoice;
      } else {
        const fallbackVoice = voices.find(voice => voice.lang.startsWith(language.split('-')[0])) || voices[0];
        utterance.voice = fallbackVoice;
      }

      utteranceRef.current = utterance;

      utterance.onstart = () => setIsPlaying(true);
      utterance.onend = () => setIsPlaying(false);

      window.speechSynthesis.speak(utterance);
    } else {
      alert('お使いのブラウザは音声合成に対応していません。');
    }
  };

  const stopSpeaking = () => {
    window.speechSynthesis.cancel();
    setIsPlaying(false);
  };

  const handleLanguageChange = (e) => {
    const newLang = e.target.value;
    setLanguage(newLang);
    const voiceForNewLang = voices.find(voice => voice.lang === newLang);
    setSelectedVoice(voiceForNewLang || null);
    setShowWarning(!voiceForNewLang);
  };

  return (
    <div className="flex flex-col items-center p-4 bg-pink-500 text-white min-h-screen">
      <h1 className="text-3xl font-bold mb-4">多言語音声読み上げアプリ</h1>
      {showWarning && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>警告</AlertTitle>
          <AlertDescription>
            選択された言語の音声が利用できません。別の音声で代用します。
          </AlertDescription>
        </Alert>
      )}
      <select
        className="mb-4 p-2 text-black w-full max-w-md"
        value={language}
        onChange={handleLanguageChange}
      >
        {supportedLanguages.map(lang => (
          <option key={lang.code} value={lang.code}>{lang.name}</option>
        ))}
      </select>
      <select
        className="mb-4 p-2 text-black w-full max-w-md"
        value={selectedVoice ? selectedVoice.name : ''}
        onChange={(e) => setSelectedVoice(voices.find(v => v.name === e.target.value) || null)}
      >
        {voices.filter(voice => voice.lang.startsWith(language.split('-')[0])).map(voice => (
          <option key={voice.name} value={voice.name}>
            {voice.name}
          </option>
        ))}
      </select>
      <textarea
        className="w-full max-w-md h-40 p-2 mb-4 text-black"
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="テキストを入力してください"
      />
      {isTranslating && <p className="mb-4">翻訳中...</p>}
      {translatedText && (
        <div className="w-full max-w-md mb-4 p-2 bg-white text-black rounded">
          <h3 className="font-bold">翻訳結果:</h3>
          <p>{translatedText}</p>
        </div>
      )}
      <div className="flex justify-center space-x-4 mb-4">
        {[0.5, 0.75, 1, 1.25, 1.5, 2].map((speed) => (
          <button
            key={speed}
            className={`px-4 py-2 rounded ${rate === speed ? 'bg-white text-pink-500' : 'bg-pink-600'}`}
            onClick={() => setRate(speed)}
          >
            {speed}x
          </button>
        ))}
      </div>
      <div className="flex space-x-4">
        <button
          className="bg-white text-pink-500 px-6 py-2 rounded"
          onClick={isPlaying ? stopSpeaking : speak}
          disabled={isTranslating}
        >
          {isPlaying ? '■' : '▶'}
        </button>
        <button
          className="bg-white text-pink-500 px-6 py-2 rounded"
          onClick={stopSpeaking}
          disabled={isTranslating}
        >
          ↺
        </button>
      </div>
    </div>
  );
};

export default TextToSpeechApp;
